<template>
	<div class="subscribe-box">
		<div class="subscribe-box-left">
			<div class="title-box">
				<div class="title">订阅名称</div>
				<div class="title-right">
					<div class="add" @click="addSubscribe">
						<i class="el-icon-plus"></i>
						新增订阅
					</div>
					<!-- <div class="setting" @click="toPushSet">
						<i class="el-icon-setting"></i>
						推送设置
					</div> -->
				</div>
			</div>
			<div class="search">
				<el-input placeholder="请输入订阅内容" size="small" clearable v-model="conPO.searchContent"
					@change="handleSubscribe" @clear="handleSubscribe">
					<template slot="append">搜索</template>
				</el-input>
			</div>
			<div class="list">
				<div class="list-item" :class="{'list-item-checked': subscribeIndex == index}" v-for="(item, index) in subscribeList" :key="index" @click="handleSubscribeDetail(item, index)">
					<div class="list-item-title-box">
						<div class="list-item-title">{{ item.subscribeName }}</div>
						<div v-if="item.unreadCount && item.unreadCount * 1 > 0" class="no-read">{{ item.unreadCount * 1 > 99 ? "99+" : item.unreadCount * 1 }}</div>
					</div>
					<div class="desc-box">
						<div v-for="(ite, i) in subscribeTopicsList" :key="ite.name" class="subscribe-topics" :class="item.open == ite.value ? 'check' : ''" @click.stop="openItem(index, ite.value)" v-if="item.subscribeTopics.indexOf(ite.value) != -1">
							{{ ite.name }}
							<i class="subscribe-topics-icon" :class="item.open == ite.value ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{color: item.open == ite.value ? '#ffffff' : '#3E7EF2'}"></i>
						</div>
						<div class="zhanwei"></div>
						<el-dropdown @command="handleCommand($event, item.subscribeId)">
							<span class="el-dropdown-link">
								<i class="el-icon-more" size="20px" color="#999" @click.stop=""></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item icon="el-icon-edit" command="edit">修改</el-dropdown-item>
								<el-dropdown-item icon="el-icon-delete" command="delete">删除</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="info" v-show="item.open != '1'">
						<template v-if="item.open == '60611007'">
							<div class="row">
								所在地区：
								<div class="value">
									{{ getkeyAddressText(item.mdsSubscribeProposedProjectDTO.keyAddressName) || "不限" }}
								</div>
							</div>
							<div class="row">
								项目类型：
								<div class="value">
									{{ item.mdsSubscribeProposedProjectDTO.keyProjectType || "不限" }}
								</div>
							</div>
							<div class="row" v-if="!item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart && !item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd">
								投资金额：
								<div class="value">不限</div>
							</div>
							<div class="row" v-else>
								投资金额：
								<div class="value">
									<template v-if="item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart == -1">
										其他
									</template>
									<template v-else>
										{{ (item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart ? item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart / 10000 + "万元" : "0") + "-" + (item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd ? item.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd / 10000 + "万元" : "不限") }}
									</template>
								</div>
							</div>
							<div class="row">
								业主单位：
								<div class="value">
									{{ item.mdsSubscribeProposedProjectDTO.keyProprietor || "不限" }}
								</div>
							</div>
							<div class="row">
								项目所处阶段：
								<div class="value">
									{{ item.mdsSubscribeProposedProjectDTO.keyProjectStageName || "不限" }}
								</div>
							</div>
							<div class="row">
								关键词：
								<div class="value">
									{{ item.mdsSubscribeProposedProjectDTO.keySearch || "不限" }}
								</div>
							</div>
						</template>
						<template v-if="item.open == '60611009'">
							<div class="row">
								所在地区：
								<div class="value">
									{{ getkeyAddressText(item.mdsSubscribeTenderDTO.keyAddressName) || "不限" }}
								</div>
							</div>
							<div class="row">
								项目类型：
								<div class="value">
									{{ item.mdsSubscribeTenderDTO.keyProjectType || "不限" }}
								</div>
							</div>
							<div class="row" v-if="!item.mdsSubscribeTenderDTO.keyProjectPriceStart && !item.mdsSubscribeTenderDTO.keyProjectPriceEnd">
								招标金额：
								<div class="value">不限</div>
							</div>
							<div class="row" v-else>
								招标金额：
								<div class="value">
									<template v-if="item.mdsSubscribeTenderDTO.keyProjectPriceStart == -1">
										其他
									</template>
									<template v-else>
										{{ (item.mdsSubscribeTenderDTO.keyProjectPriceStart ? item.mdsSubscribeTenderDTO.keyProjectPriceStart / 10000 + "万元" : "0") + "-" + (item.mdsSubscribeTenderDTO.keyProjectPriceEnd ? item.mdsSubscribeTenderDTO.keyProjectPriceEnd / 10000 + "万元" : "不限") }}
									</template>
								</div>
							</div>
							<div class="row">
								招标代理机构：
								<div class="value">
									{{ item.mdsSubscribeTenderDTO.keyAgency || "不限" }}
								</div>
							</div>
							<div class="row">
								招标人：
								<div class="value">
									{{ item.mdsSubscribeTenderDTO.keyTenderee || "不限" }}
								</div>
							</div>
							<div class="row">
								关键词：
								<div class="value">
									{{ item.mdsSubscribeTenderDTO.keySearch || "不限" }}
								</div>
							</div>
						</template>
						<template v-if="item.open == '60611008'">
							<div class="row">
								所在地区：
								<div class="value">
									{{ getkeyAddressText(item.mdsSubscribeWinningBidDTO.keyAddressName) || "不限" }}
								</div>
							</div>
							<div class="row">
								项目类型：
								<div class="value">
									{{ item.mdsSubscribeWinningBidDTO.keyProjectType || "不限" }}
								</div>
							</div>
							<div class="row" v-if="!item.mdsSubscribeWinningBidDTO.keyProjectPriceStart && !item.mdsSubscribeWinningBidDTO.keyProjectPriceEnd">
								中标金额：
								<div class="value">不限</div>
							</div>
							<div class="row" v-else>
								中标金额：
								<div class="value">
									<template v-if="item.mdsSubscribeWinningBidDTO.keyProjectPriceStart == -1">
										其他
									</template>
									<template v-else>
										{{ (item.mdsSubscribeWinningBidDTO.keyProjectPriceStart ? item.mdsSubscribeWinningBidDTO.keyProjectPriceStart / 10000 + "万元" : "0") + "-" + (item.mdsSubscribeWinningBidDTO.keyProjectPriceEnd ? item.mdsSubscribeWinningBidDTO.keyProjectPriceEnd / 10000 + "万元" : "不限") }}
									</template>
								</div>
							</div>
							<div class="row">
								招标人：
								<div class="value">
									{{ item.mdsSubscribeWinningBidDTO.keyTenderee || "不限" }}
								</div>
							</div>
							<div class="row">
								中标人：
								<div class="value">
									{{ item.mdsSubscribeWinningBidDTO.keyBidWinner || "不限" }}
								</div>
							</div>
							<div class="row">
								关键词：
								<div class="value">
									{{ item.mdsSubscribeWinningBidDTO.keySearch || "不限" }}
								</div>
							</div>
						</template>
						<template v-if="item.open == '60611010'">
							<div class="row">
								所在地区：
								<div class="value">
									{{ getkeyAddressText(item.mdsSubscribeLandTransactionDTO.keyAddressName) || "不限" }}
								</div>
							</div>
							<div class="row">
								土地用途：
								<div class="value">
									{{ item.mdsSubscribeLandTransactionDTO.keyLandUse || "不限" }}
								</div>
							</div>
							<div class="row">
								土地面积：
								<div class="value">
									<template v-if="item.mdsSubscribeLandTransactionDTO.keyLandAreaStart == -1">
										其他
									</template>
									<template v-else>
										{{ item.mdsSubscribeLandTransactionDTO.keyLandAreaEnd || item.mdsSubscribeLandTransactionDTO.keyLandAreaStart ? `${item.mdsSubscribeLandTransactionDTO.keyLandAreaStart || "0"}-${item.mdsSubscribeLandTransactionDTO.keyLandAreaEnd || "不限"}` : "不限" }}
									</template>
								</div>
							</div>
							<div class="row">
								土地使用权人：
								<div class="value">
									{{ item.mdsSubscribeLandTransactionDTO.keyLandUseRight || "不限" }}
								</div>
							</div>
							<div class="row">
								约定开工日期：
								<div class="value">
									{{ item.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd || item.mdsSubscribeLandTransactionDTO.keyCommencementDateStart ? `${item.mdsSubscribeLandTransactionDTO.keyCommencementDateStart || "不限"}-${item.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd || "不限"}` : "不限" }}
								</div>
							</div>
							<div class="row">
								关键词：
								<div class="value">
									{{ item.mdsSubscribeLandTransactionDTO.keySearch || "不限" }}
								</div>
							</div>
						</template>
					</div>
				</div>
				<div v-if="conPO.total == 0 && hasSub" style="margin-top: 100px">
					<empty :name="'暂无数据'"></empty>
				</div>
				<div v-if="conPO.total == 0 && !hasSub" style="margin-top: 100px">
					<div class="flex flex-col items-center px-24">
						<img src="https://minio1-view-dit-paas.ctcemti.com/public-mticloud-app-mds/2024/01/25/db50ee14-fda2-4a3d-af15-d4f1590765e8.png" style="width: 80px;" />
						<div class="text-18 mt-20 fw-6">订阅能做什么？</div>
						<div class="mt-20 text-center cr8b text-12">
							对于拟建信息等各类商机信息，您可按地区、投资额、项目所处阶段、项目类型、招标人/中标人名称等维度进行个性化订阅，为您实时、精准推送最新商机信息！
						</div>
						<div class="mt-32"></div>
						<el-button type="primary" style="width: 220px;" @click="addSubscribe()" round>快速创建订阅</el-button>
					</div>
				</div>
			</div>
			<div class="footer">
				<el-pagination layout="prev, pager, next" hide-on-single-page :current-page.sync="conPO.pageNum" :total="conPO.total" :page-size="conPO.pageSize" @current-change="getSubscribe"></el-pagination>
			</div>
		</div>

		<div class="subscribe-box-right">
			<div class="title-box detail-title-box" v-if="subscribeList && subscribeList[subscribeIndex]">
				<div class="title">{{ subscribeList[subscribeIndex].subscribeName }}</div>
				<div class="el-tabs-content">
					<el-tabs v-model="activeName2" size="max">
						<el-tab-pane v-for="(ite, i) in subscribeTopicsList" :key="ite.name" :name="ite.value" :label="ite.name" v-if="subscribeList[subscribeIndex].subscribeTopics.indexOf(ite.value) != -1" />
					</el-tabs>
				</div>
			</div>
			<div class="right-content" v-if="(subscribeIndex || subscribeIndex == 0) && subscribeList && subscribeList[subscribeIndex]">
				<proposedProject v-if="activeName2 == '60611007'" :subscribeId="subscribeList[subscribeIndex].subscribeId"></proposedProject>

				<landTransaction v-if="activeName2 == '60611010'" :subscribeId="subscribeList[subscribeIndex].subscribeId"></landTransaction>

				<biddingInformation v-if="activeName2 == '60611009'" :subscribeId="subscribeList[subscribeIndex].subscribeId"></biddingInformation>

				<biddingProjectInfo v-if="activeName2 == '60611008'" :subscribeId="subscribeList[subscribeIndex].subscribeId"></biddingProjectInfo>
			</div>
		</div>
		<el-dialog :visible.sync="visible" width="50%" class="dataDescriptionDialog">
			<img class="dataDescriptionImg" src="../../../assets/img/successful/dataDescription.png" alt="" />
		</el-dialog>
		<el-dialog :visible.sync="addVisible" width="1000px" :title="actionId ? '修改订阅' : '新增订阅'" class="addSubscribeDialog"
			:close-on-click-modal="false" :destroy-on-close="true" v-if="addVisible">
			<Add ref="add" :subscribeId="actionId" :key="'add' + new Date().getTime()" @toPushSet="toPushSet"></Add>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reset()" round>重 置</el-button>
				<el-button type="primary" @click="createSubscribe" round>{{ actionId ? "保存修改" : "生成订阅" }}</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="pushSetVisible" width="30%" title="推送设置" class="addSubscribeDialog">
			<pushSet ref="pushSet" @changePushSettingId="changePushSettingId" :pushSettingId="pushSettingId"></pushSet>
		</el-dialog>
		<el-dialog :visible.sync="selfSelectedPopShow" width="30%" title="自选订阅" class="addSubscribeDialog" @close="selfSelectedClose" @open="selfSelectedOpen">
			<div>
				<div class="flex flex-col items-center">
					<div class="text-16 mb-28">您是注册用户，可免费体验一周</div>
					<div>
						<el-button type="primary" @click="receiveSubscribe">立即使用</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="promptShow" width="30%" :title="promptTitle" class="addSubscribeDialog" @close="selfSelectedClose" @open="selfSelectedOpen">
			<div>
				<div class="flex flex-col items-center">
					<div class="text-16 mb-28">{{ remindContent }}</div>
					<div>
						<el-button @click="cancel">我知道了</el-button>
						<el-button type="primary" @click="confirm">{{ confirmText }}</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import LoginModal from "@/components/loginModal";
import proposedProject from "@/views/industry/proposedProject";
import landTransaction from "@/views/industry/landTransaction";
import biddingInformation from "@/views/industry/biddingInformation";
import biddingProjectInfo from "@/views/industry/biddingProjectInfo";
import Add from "../components/add/index.vue";
import pushSet from "../components/pushSet/index.vue";
import Empty from "@/components/Empty";
export default {
	components: {
		LoginModal,
		proposedProject,
		landTransaction,
		biddingInformation,
		biddingProjectInfo,
		Add,
		pushSet,
		Empty,
	},
	data() {
		return {
			conPO: {
				searchContent: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			subscribeList: [],
			subscribeIndex: 0,
			subscribeTopicsList: [
				{
					value: "60611007",
					name: "拟建信息",
				},
				{
					value: "60611010",
					name: "土地交易",
				},
				{
					value: "60611009",
					name: "招标信息",
				},
				{
					value: "60611008",
					name: "中标信息",
				},
			],
			activeName: "",
			activeName2: "",
			addVisible: false,
			pushSetVisible: false,
			visible: false,
			actionId: "",
			pushSettingId: "1",
			// 普通用户弹窗
			selfSelectedPopShow: false,
			promptShow: false,
			// 弹窗提醒内容
			remindContent: "",
			confirmText: "",
			promptTitle: "",
			hasSub: false,
		};
	},
	created() {},
	computed: {},
	mounted() {
		this.expirationReminder();
		this.getSubscribe();
	},
	methods: {
		openItem(index, value) {
			if (this.subscribeList[index].open == value) {
				this.subscribeList[index].open = "1";
			} else {
				this.subscribeList[index].open = value;
			}
		},
		getkeyAddressText(value) {
			if (!value) {
				return "";
			}
			if (value.indexOf("code") != -1) {
				value = JSON.parse(value);
				let province = value.province || [],
					city = value.city || [];

				// 剔除有下级市的省
				let arr = [];
				province.map(item => {
					let count = 0;
					city.map(i => {
						if (i.code.slice(0, 2) == item.code.slice(0, 2)) {
							count++;
						}
					});
					if (count == 0) {
						arr.push(item);
					}
				});
				province = arr;

				let proSelectNameList = province.map(item => {
					return item.name;
				});
				let citySelectNameList = city.map(item => {
					return item.name;
				});

				let selectedMulAreaName = "";
				if (proSelectNameList.length) {
					selectedMulAreaName = proSelectNameList.join(",");
				}
				if (proSelectNameList.length && citySelectNameList.length) {
					selectedMulAreaName = selectedMulAreaName + ",";
				}
				if (citySelectNameList.length) {
					selectedMulAreaName = selectedMulAreaName + citySelectNameList.join(",");
				}
				if (selectedMulAreaName.indexOf("全部,") != -1) {
					selectedMulAreaName = "全部";
				}
				return selectedMulAreaName;
			} else {
				value = JSON.parse(value);
				let text = "";
				if (value && value.province) {
					text = value.province;
				}
				if (value && value.city) {
					text = text + (text ? "," : "") + value.city;
				}
				return text;
			}
		},
		addSubscribe() {
			this.actionId = "";
			this.interceptFindUserSubscribeReminder();
		},
		toPushSet() {
			this.pushSettingId = this.$refs.add.form.pushSettingId;
			this.pushSetVisible = true;
			console.log("this.$refs.add.form", this.$refs.add.form.pushSettingId);
		},
		handleSubscribe() {
			this.conPO.pageNum = 1;
			this.getSubscribe();
		},
		getSubscribe() {
			this.$api.subscribe.subscribePage(this.conPO).then(res => {
				if (res.code == 200) {
					if (res.total > 0) {
						this.hasSub = true;
					}
					res.rows.map(item => {
						item.open = "1";
						if (item.subscribeTopics && item.subscribeTopics.indexOf("60611010") != -1 && item.mdsSubscribeLandTransactionDTO) {
							let {keyLandAreaStart, keyLandAreaEnd} = item.mdsSubscribeLandTransactionDTO;
							if (keyLandAreaStart && keyLandAreaStart != -1) {
								keyLandAreaStart = keyLandAreaStart + "㎡";
							}
							if (keyLandAreaEnd && keyLandAreaEnd != -1) {
								keyLandAreaEnd = keyLandAreaEnd + "㎡";
							}
							item.mdsSubscribeLandTransactionDTO.keyLandAreaStart = keyLandAreaStart;
							item.mdsSubscribeLandTransactionDTO.keyLandAreaEnd = keyLandAreaEnd;
						}
					});
					this.subscribeList = res.rows;
					this.conPO.total = res.total;
					if(this.subscribeList.length>0){
						this.handleSubscribeDetail(this.subscribeList[0], 0);
					}
					this.setActiveName2();
				} else {
					// if(res.msg?.res.msg){
					// 	this.$message.error(res.msg?.res.msg);
					// }
				}
			});
		},
		setActiveName2() {
			let item = this.subscribeList[this.subscribeIndex];
			if (item && item.subscribeTopics) {
				this.activeName2 = item.subscribeTopics.split(",")[0];
			}
		},
		handleCommand(e, id) {
			if (e == "edit") {
				this.actionId = id;
				this.addVisible = true;
			} else {
				this.$confirm(`是否要删除此条订阅？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(res => {
						console.log(res);
						if (res == "confirm") {
							this.$api.subscribe.subscribeDelete(id).then(res => {
								this.$message.info("已删除");
								this.getSubscribe();
							});
						}
					})
					.catch(() => {});
			}
		},
		handleSubscribeDetail(item, index) {
			this.subscribeIndex = null;
			this.activeName = "0";

			setTimeout(() => {
				this.subscribeIndex = index;
				if (item.subscribeTopics && item.subscribeTopics.length) {
					this.activeName2 = item.subscribeTopics.split(",")[0];
				}
				setTimeout(() => {
					this.subscribeList[index].unreadCount = 0
				}, 1000);
				this.getSubscribeDetail(item)
			}, 1);
		},
		getSubscribeDetail(item) {
			// 取消标点
			this.$api.subscribe.subscribeDetail({subscribeId:item.subscribeId})
		},
		// 生成订阅
		createSubscribe() {
			// add
			this.$refs.add.form.pushSettingId = this.pushSettingId;
			let fuc = this.$api.subscribe.addSubscribe;
			if (this.actionId) {
				fuc = this.$api.subscribe.subscribeUpdate;
			}
			fuc(JSON.stringify(this.$refs.add.form))
				.then(res => {
					if (res.code == 200) {
						this.$message.success("创建成功");
					} else {
						this.$message.error(res.msg);
					}
					this.addVisible = false;
					this.getSubscribe();
					this.$refs.add.reset(true);
				})
				.catch(err => {
					this.$message.error(err.msg);
				});
		},
		// 重置
		reset() {
			this.$refs.add.reset();
		},
		// 更改 推送频次类型主键
		changePushSettingId(pushSettingId) {
			console.log("pushSettingId", pushSettingId);
			this.pushSettingId = pushSettingId;
		},

		// 自选订阅弹窗
		selfSelectedClose() {},
		selfSelectedOpen() {},
		// 注册用户领取订阅
		receiveSubscribe() {
			this.$api.subscribe.receiveSubscribe().then(res => {
				if (res.code == 200) {
					this.actionId = "";
					this.addVisible = true;
					this.selfSelectedPopShow = false;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//普通用户订阅提醒
		interceptFindUserSubscribeReminder() {
			this.$api.subscribe
				.interceptFindUserSubscribeReminder()
				.then(res => {
					if (res.code == 200) {
						if (res.data.receive === false) {
							this.selfSelectedPopShow = true;
						} else if (res.data.remind === false) {
							this.actionId = "";
							this.addVisible = true;
						}
					} else {
						this.promptShow = true;
						this.promptTitle = "已过期";
						this.remindContent = res.msg;
						if (this.$session.getUsers().memberId == 1 || this.$session.getUsers().memberId == 7) {
							this.confirmText = "去购买";
						} else if (this.$session.getUsers().memberId == 3) {
							this.confirmText = "去升级";
						}
					}
				})
				.catch(err => {
					this.promptShow = true;
					this.promptTitle = "已过期";
					this.remindContent = err.msg;
					if (this.$session.getUsers().memberId == 1 || this.$session.getUsers().memberId == 7) {
						this.confirmText = "去购买";
					} else if (this.$session.getUsers().memberId == 3) {
						this.confirmText = "去升级";
					}
				});
		},
		// 进入该页面，检测是否过期
		expirationReminder() {
			this.$api.subscribe
				.interceptFindUserSubscribeReminder()
				.then(res => {
					if (this.$session.getUsers().memberId == 1 || this.$session.getUsers().memberId == 7) {
						this.confirmText = "去购买";
					} else if (this.$session.getUsers().memberId == 3) {
						this.confirmText = "去升级";
					}
					if (res.code == 200 && res.data.remind) {
						this.promptShow = true;
						this.remindContent = res.data.content;
						this.promptTitle = "即将到期";
					} else {
					}
				})
				.catch(err => {
					this.promptShow = true;
					this.promptTitle = "已过期";
					this.remindContent = err.msg;
					if (this.$session.getUsers().memberId == 1 || this.$session.getUsers().memberId == 7) {
						this.confirmText = "去购买";
					} else if (this.$session.getUsers().memberId == 3) {
						this.confirmText = "去升级";
					}
				});
		},
		confirm() {
			this.promptShow = false;
			// 会员开通
			if (this.$session.getUsers().memberId == 1 || this.$session.getUsers().memberId == 3) {
				this.toMember("upgradation");
			} else if (this.$session.getUsers().memberId == 7) {
				this.toMember();
			}
		},
		toMember(isUpgradation) {
			let url = this.$router.resolve({
				path: `/member`,
				query: {
					isUpgradation: isUpgradation,
				},
			});
			window.open(url.href, "_blank");
		},
		cancel() {
			this.promptShow = false;
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
