<template>
	<div class="add-box">
		<div class="head"></div>
		<div class="content">
			<el-form ref="form" :model="form" label-width="130px" label-position="left">
				<h4>每天推送时间</h4>
				<div class="businessKeywords-box">
					<el-form-item label="">
						<el-radio-group v-model="form.radio" @input="changeRadio">
							<el-radio :label="item.subscribePushSettingId" v-for="(item, index) in pushTimeList" :key="index">{{ item.pushName }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
			</el-form>
			<!-- <el-form ref="form" :model="form" label-width="130px" label-position="left">
				<h4>每天推送时间</h4>
				<div class="businessKeywords-box">
					<el-form-item label="">
						<span>每天一次（</span>
						<el-select v-model="pointTime" placeholder="请选择" size="mini" style="width: 60px;">
							<el-option v-for="(item,index) in options" :key="index" :label="item" :value="item"></el-option>
						</el-select>
						<span>点推送）</span>
					</el-form-item>
				</div>
			</el-form> -->
		</div>
	</div>
</template>
<script>
export default {
	name: "",
	components: {},
	mixins: [],
	props: {
		pushSettingId: {
			type: String,
			default: "1",
		},
	},
	data() {
		return {
			form: {
				radio: "1",
			},
			pushTimeList: [
				{
					pushName: "实时推送",
					pushTime: "",
					dailyTimes: "10,12,15,20",
					subscribePushSettingId: "1",
				},
				{
					pushName: "每天1次（12点推送）",
					pushTime: "",
					dailyTimes: "12",
					subscribePushSettingId: "2",
				},
				{
					pushName: "每天2次（11点、16点推送）",
					pushTime: "",
					dailyTimes: "11,16",
					subscribePushSettingId: "3",
				},
				{
					pushName: "每天3次（9点、12点、16点推送）",
					pushTime: "",
					dailyTimes: "9,12,16",
					subscribePushSettingId: "4",
				},
			],
			options: [9, 12, 18],
			pointTime: 9,
		};
	},
	computed: {},
	watch: {
		pushSettingId: {
			handler: function(newValue, oldValue) {
				console.log("newValue", newValue);
				console.log("oldValue", oldValue);
				this.form.radio = newValue;
			},
			deep: true, // 开启深度监听
			//立即处理
			immediate: true,
		},
	},
	mounted() {},
	methods: {
		onSubmit() {},
		changeRadio(e) {
			console.log("e", e);
			this.$emit("changePushSettingId", e);
		},
	},
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
