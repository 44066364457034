<template>
    <div class="input-box">
        <el-cascader class="w-full" :options="areaSelect" :props="myprops" v-model="selecedtArealist" clearable
            :collapse-tags="false" :show-all-levels="false" ref="area" @change="areaChange"></el-cascader>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "regionselect",
    components: {
    },
    props: {
        value: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            selecedtArealist: [],
            areaSelect: [],
            myprops: {
                value: 'code',
                label: 'name',
                checkStrictly: true,
                multiple: true,
                lazy: true,
                lazyLoad: (node, resolve) => {
                    if (!node || !node.data) {
                        return
                    }
                    console.log('lazyLoad--------', node.data)
                    let obj = {
                        parentRegion: node.data.regionId,
                        isLazyLoad: 10011001,
                        state: 10021001,
                        hasChildren: true
                    }
                    this.$api.system.regionList(obj).then(res => {
                        if (res.data.length > 0) {
                            res.data.map(item => {
                                item.level = 2
                                item.leaf = true
                                item.name = item.regionFullname
                                item.code = item.unionCode
                            })
                            resolve(res.data);
                        }
                    });
                }
            },
        };
    },
    computed: {
        ...mapGetters(['regionList'])
    },
    watch: {
        value(val) {
            if (val && val.length) {
                this.selecedtArealist = val
                this.showValue(val)
            } else {
                this.selecedtArealist = val
            }
        },
    },
    mounted() {
        if (this.value && this.value.length) {
            this.selecedtArealist = this.value
            this.showValue(this.value)
        } else {
            this.selecedtArealist = JSON.parse(JSON.stringify(this.value))
            this.areaSelect = JSON.parse(JSON.stringify(this.regionList))
        }
    },
    methods: {
        areaChange(e) {
            this.$nextTick(() => {
                let nodeArr = this.$refs.area.getCheckedNodes().map(item => { return item.data })
                let obj = {
                    value: e,
                    nodeArr
                }
                console.log(obj)
                this.$emit('change', obj)
            })
        },
        // 请求市区城
        getCity(item) {
            return new Promise((resolve, reject) => {
                let obj = {
                    parentRegion: item.regionId,
                    isLazyLoad: 10011001,
                    state: 10021001,
                    hasChildren: true
                }
                this.$api.system.regionList(obj).then(res => {
                    res.data.map(item => {
                        item.level = 2
                        item.leaf = true
                        item.name = item.regionFullname
                        item.code = item.unionCode
                    })
                    resolve(res.data)
                })

            })
        },
        async showValue(selecedtArealist) {
            // 由于cascader lazy开启动态加载所以 v-model二级的值不会回显，所以要根据code还原对应的children 
            let regionData = JSON.parse(JSON.stringify(this.regionList))
            let addchildrenIndexArray = []//需要请求子级的索引数组
            selecedtArealist.map(item => {
                if (item.length > 1) {
                    regionData.map((areaItem, index) => {
                        if (item[0] == areaItem.code) {
                            addchildrenIndexArray.push(index)
                        }
                    })
                }
            })
            Promise.all(addchildrenIndexArray.map(async item => {
                regionData[item].children = await this.getCity(regionData[item])
            })).then(() => {
                // 所有子级请求完成，回显数据
                this.areaSelect = regionData
            })
        }

    }
};
</script>
<style lang="less" scoped></style>
