<template>
	<div class="add-box">
		<div class="head"></div>
		<div class="content">
			<el-form ref="form" :model="form" label-width="130px" label-position="left">
				<div class="setting text-right mb-10" @click="toPushSet">
					<i class="el-icon-setting"></i>
					推送设置
				</div>
				<el-form-item label="订阅名称">
					<el-input v-model="form.subscribeName" placeholder="请输入，默认为关注主题名称"></el-input>
				</el-form-item>
				<h4>添加关注主题（至少选择一类）</h4>
				<el-form-item label="">
					<div class="projectType-box flex">
						<div v-for="(item, index) in projectType" :key="index" class="project-type-item px-16 round-20" :class="activeIndex == index ? 'active-bg-color' : subscribeTopicsList.indexOf(item.informationValue) !== -1 ? 'active-topic-bg-color' : ''" @click="checkboxClick(index)" :label="item.informationValue">
							{{ item.informationType }}
							<i :class="activeIndex == index ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
						</div>
					</div>
				</el-form-item>
				<h4>订阅范围选择（至少选择一项，未选则默认为全部）</h4>
				<!-- 拟建信息表单 -->
				<div class="mdsSubscribeProposedProjectDTO" v-show="activeIndex == 0">
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>1、所在地区</span>
						</div>
						<div class="flex-1">
							<regionSelect v-model="mdsSubscribeProposedProjectDTO.selecedtArealist" @change="areaChange($event, 'mdsSubscribeProposedProjectDTO')" />
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>2、项目类型</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeProposedProjectDTO.keyProjectTypeList" multiple placeholder="请选择" @change="selectType($event, 'mdsSubscribeProposedProjectDTO')">
								<el-option v-for="item in tenderProjectTypeList" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>3、投资额</span>
						</div>
						<div class="flex-1 flex">
							<el-select class="w-1\/3" style="width: 30%;" v-model="mdsSubscribeProposedProjectDTO.selectedInvestmentList" placeholder="请选择" @change="selectInvestment($event, 'mdsSubscribeProposedProjectDTO')">
								<el-option v-for="item in investmentList" :key="item.id" :label="item.name" :value="item.value"></el-option>
							</el-select>
							<div class="flex text-center custom-box">
								<div class="w-64 lh-40">自定义</div>
								<div class="">
									<el-input class="hide-buttons" v-model="mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart" clearable type="number" style="width: 120px;" placeholder="最小金额" @input="changeCustomInvestmentStart('mdsSubscribeProposedProjectDTO')" />
									<span class="ml-8">万</span>
								</div>
								<div class="w-10 lh-40 ml-4 mr-4">-</div>
								<div>
									<el-input class="hide-buttons" v-model="mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd" clearable type="number" style="width: 120px;" placeholder="最大金额" @input="changeCustomInvestmentEnd('mdsSubscribeProposedProjectDTO')" />
									<span class="ml-8">万</span>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>4、业主单位</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeProposedProjectDTO.keyProprietorList" @change="selectProprietor" multiple filterable remote reserve-keyword placeholder="请输入业主单位" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>5、项目所处阶段</span>
						</div>
						<div class="flex-1">
							<el-cascader
								class="w-full"
								:options="statusList"
								:props="{
									value: 'dictValue',
									label: 'dictLabel',
									children: 'childCodeList',
									checkStrictly: true,
									multiple: true,
								}"
								multiline="true"
								clearable
								v-model="keyProjectStageList"
								:collapse-tags="false"
								:show-all-levels="false"
								ref="mdsSubscribeProposedProjectDTOarea"
								@change="statusChange"
							></el-cascader>
						</div>
					</div>

					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 lh-40">
							<span>6、自定义关键词</span>
						</div>
						<div class="flex-1">
							<el-input class="w-4\/5 lh-40" v-model="mdsSubscribeProposedProjectDTO.customKeyword" @change="changeCustomInvestment">
								<el-button slot="suffix" type="primary" size="small" @click="addKeyword('mdsSubscribeProposedProjectDTO')">添加</el-button>
							</el-input>
						</div>
					</div>
					<div class="selectedBox pl-176">
						<p class="remind" v-if="mdsSubscribeProposedProjectDTO.customKeywordList && mdsSubscribeProposedProjectDTO.customKeywordList.length > 2 && mdsSubscribeProposedProjectDTO.isKeyWordTip">
							*关键词最多为3个！
						</p>
						<div class="selectedWordList">
							<div class="selectedWord" v-for="(item, i) in mdsSubscribeProposedProjectDTO.customKeywordList" :key="i">
								<div class="selectedWordText">
									<span class="wordText">{{ item }}</span>
									<i class="el-icon-close" name="close" size="14" @click="deleteWord(i, 'mdsSubscribeProposedProjectDTO')"></i>
								</div>
							</div>
						</div>
						<div class="explan" v-if="mdsSubscribeProposedProjectDTO.customKeywordList && mdsSubscribeProposedProjectDTO.customKeywordList.length > 0">
							注：
							<span v-for="(item, i) in mdsSubscribeProposedProjectDTO.customKeywordList" :key="i">
								<span>
									<span>"</span>
									{{ item }}
									<span>"</span>
								</span>
								<span v-show="mdsSubscribeProposedProjectDTO.customKeywordList && mdsSubscribeProposedProjectDTO.customKeywordList.length != i + 1">或</span>
							</span>
						</div>
					</div>
				</div>
				<!-- 土地交易表单 -->
				<div class="mdsSubscribeLandTransactionDTO" v-show="activeIndex == 1">
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>1、所在地区</span>
						</div>
						<div class="flex-1">
							<regionSelect v-model="mdsSubscribeLandTransactionDTO.selecedtArealist" @change="areaChange($event, 'mdsSubscribeLandTransactionDTO')" />
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>2、土地用途</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="keyLandUseList" multiple placeholder="请选择" @change="selectLandUse()">
								<el-option v-for="item in landUseList" :key="item.id" :label="item.dictLabel" :value="item.dictLabel"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>3、土地面积</span>
						</div>
						<div class="flex-1 flex">
							<el-select class="w-1\/3" style="width: 30%;" v-model="selectedLandAreaList" placeholder="请选择" @change="selectLandArea()">
								<el-option v-for="item in landAreaList" :key="item.id" :label="item.name" :value="item.value"></el-option>
							</el-select>
							<div class="flex text-center custom-box">
								<div class="w-64 lh-40">自定义</div>
								<div class="">
									<el-input class="hide-buttons" v-model="mdsSubscribeLandTransactionDTO.keyLandAreaStart" clearable type="number" style="width: 120px;" placeholder="最小面积" @input="changeKeyLandAreaStart" />
									<span class="ml-8">㎡</span>
								</div>
								<div class="w-10 lh-40 ml-4 mr-4">-</div>
								<div>
									<el-input class="hide-buttons" v-model="mdsSubscribeLandTransactionDTO.keyLandAreaEnd" clearable type="number" style="width: 120px;" placeholder="最大面积" @input="changeKeyLandAreaEnd" />
									<span class="ml-8">㎡</span>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>4、土地使用权人</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeLandTransactionDTO.keyLandUseRightList" @change="selectUseRight" multiple filterable remote reserve-keyword placeholder="请输入土地使用权人" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>5、约定开工日期</span>
						</div>
						<div class="flex-1 flex">
							<el-select class="w-1\/3" style="width: 30%;" v-model="selectedTime" placeholder="请选择" @change="selectTime()">
								<el-option v-for="item in timeList" :key="item.id" :label="item.text" :value="item.count"></el-option>
							</el-select>
							<div class="flex text-center custom-box">
								<div class="w-64 lh-40 custom">自定义</div>
								<div>
									<el-date-picker style="width: 150px;" v-model="mdsSubscribeLandTransactionDTO.startYear" type="date" :picker-options="startTime" placeholder="开始时间" value-format="yyyy-MM-dd" @change="changeYear"></el-date-picker>
								</div>
								<div class="w-10 lh-40 ml-8 mr-8">至</div>
								<div class=" ml-8">
									<el-date-picker style="width: 150px;" v-model="mdsSubscribeLandTransactionDTO.endYear" type="date" :picker-options="endTime" placeholder="结束时间" value-format="yyyy-MM-dd" @change="changeYear"></el-date-picker>
								</div>
							</div>
						</div>
					</div>

					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 lh-40">
							<span>6、自定义关键词</span>
						</div>
						<div class="flex-1">
							<el-input class="w-4\/5 lh-40" v-model="mdsSubscribeLandTransactionDTO.customKeyword" @change="changeCustomInvestment">
								<el-button slot="suffix" type="primary" size="small" @click="addKeyword('mdsSubscribeLandTransactionDTO')">添加</el-button>
							</el-input>
						</div>
					</div>
					<div class="selectedBox pl-176">
						<p class="remind" v-if="mdsSubscribeLandTransactionDTO.customKeywordList && mdsSubscribeLandTransactionDTO.customKeywordList.length > 2 && mdsSubscribeLandTransactionDTO.isKeyWordTip">
							*关键词最多为3个！
						</p>
						<div class="selectedWordList">
							<div class="selectedWord" v-for="(item, i) in mdsSubscribeLandTransactionDTO.customKeywordList" :key="i">
								<div class="selectedWordText">
									<span class="wordText">{{ item }}</span>
									<i class="el-icon-close" name="close" size="14" @click="deleteWord(i, 'mdsSubscribeLandTransactionDTO')"></i>
								</div>
							</div>
						</div>
						<div class="explan" v-if="mdsSubscribeLandTransactionDTO.customKeywordList && mdsSubscribeLandTransactionDTO.customKeywordList.length > 0">
							注：
							<span v-for="(item, i) in mdsSubscribeLandTransactionDTO.customKeywordList" :key="i">
								<span>
									<span>"</span>
									{{ item }}
									<span>"</span>
								</span>
								<span v-show="mdsSubscribeLandTransactionDTO.customKeywordList && mdsSubscribeLandTransactionDTO.customKeywordList.length != i + 1">或</span>
							</span>
						</div>
					</div>
				</div>
				<!-- 招标信息表单 -->
				<div class="mdsSubscribeTenderDTO" v-show="activeIndex == 2">
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>1、所在地区</span>
						</div>
						<div class="flex-1">
							<regionSelect v-model="mdsSubscribeTenderDTO.selecedtArealist" @change="areaChange($event, 'mdsSubscribeTenderDTO')" />
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>2、项目类型</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeTenderDTO.keyProjectTypeList" multiple placeholder="请选择" @change="selectType($event, 'mdsSubscribeTenderDTO')">
								<el-option v-for="item in tenderProjectTypeList" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>3、招标金额</span>
						</div>
						<div class="flex-1 flex">
							<el-select class="w-1\/3" style="width: 30%;" v-model="mdsSubscribeTenderDTO.selectedInvestmentList" placeholder="请选择" @change="selectInvestment($event, 'mdsSubscribeTenderDTO')">
								<el-option v-for="item in investmentList" :key="item.id" :label="item.name" :value="item.value"></el-option>
							</el-select>
							<div class="flex text-center custom-box">
								<div class="w-64 lh-40">自定义</div>
								<div class="">
									<el-input class="hide-buttons" v-model="mdsSubscribeTenderDTO.keyProjectInvestmentStart" clearable type="number" style="width: 120px;" placeholder="最小金额" @change="changeCustomInvestmentStart('mdsSubscribeTenderDTO')" />
									<span class="ml-8">万</span>
								</div>
								<div class="w-10 lh-40 ml-4 mr-4">-</div>
								<div>
									<el-input class="hide-buttons" v-model="mdsSubscribeTenderDTO.keyProjectInvestmentEnd" clearable type="number" style="width: 120px;" placeholder="最大金额" @change="changeCustomInvestmentEnd('mdsSubscribeTenderDTO')" />
									<span class="ml-8">万</span>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>4、招标人</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeTenderDTO.keyTendereeList" @change="selectDepartment('mdsSubscribeTenderDTO', 'keyTendereeList', 'keyTenderee')" multiple filterable remote reserve-keyword placeholder="请输入招标人" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>5、招标代理机构</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeTenderDTO.keyAgencyList" @change="selectDepartment('mdsSubscribeTenderDTO', 'keyAgencyList', 'keyAgency')" multiple filterable remote reserve-keyword placeholder="请输入招标代理机构" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>

					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 lh-40">
							<span>6、自定义关键词</span>
						</div>
						<div class="flex-1">
							<el-input class="w-4\/5 lh-40" v-model="mdsSubscribeTenderDTO.customKeyword" @change="changeCustomInvestment">
								<el-button slot="suffix" type="primary" size="small" @click="addKeyword('mdsSubscribeTenderDTO')">添加</el-button>
							</el-input>
						</div>
					</div>
					<div class="selectedBox pl-176">
						<p class="remind" v-if="mdsSubscribeTenderDTO.customKeywordList && mdsSubscribeTenderDTO.customKeywordList.length > 2 && mdsSubscribeTenderDTO.isKeyWordTip">
							*关键词最多为3个！
						</p>
						<div class="selectedWordList">
							<div class="selectedWord" v-for="(item, i) in mdsSubscribeTenderDTO.customKeywordList" :key="i">
								<div class="selectedWordText">
									<span class="wordText">{{ item }}</span>
									<i class="el-icon-close" name="close" size="14" @click="deleteWord(i, 'mdsSubscribeTenderDTO')"></i>
								</div>
							</div>
						</div>
						<div class="explan" v-if="mdsSubscribeTenderDTO.customKeywordList && mdsSubscribeTenderDTO.customKeywordList.length > 0">
							注：
							<span v-for="(item, i) in mdsSubscribeTenderDTO.customKeywordList" :key="i">
								<span>
									<span>"</span>
									{{ item }}
									<span>"</span>
								</span>
								<span v-show="mdsSubscribeTenderDTO.customKeywordList && mdsSubscribeTenderDTO.customKeywordList.length != i + 1">或</span>
							</span>
						</div>
					</div>
				</div>
				<!-- 中标信息表单 -->
				<div class="mdsSubscribeWinningBidDTO" v-show="activeIndex == 3">
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>1、所在地区</span>
						</div>
						<div class="flex-1">
							<regionSelect v-model="mdsSubscribeWinningBidDTO.selecedtArealist" @change="areaChange($event, 'mdsSubscribeWinningBidDTO')" />
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>2、项目类型</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeWinningBidDTO.keyProjectTypeList" multiple placeholder="请选择" @change="selectType($event, 'mdsSubscribeWinningBidDTO')">
								<el-option v-for="item in tenderProjectTypeList" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>3、中标金额</span>
						</div>
						<div class="flex-1 flex">
							<el-select class="w-1\/3" style="width: 30%;" v-model="mdsSubscribeWinningBidDTO.selectedInvestmentList" placeholder="请选择" @change="selectInvestment($event, 'mdsSubscribeWinningBidDTO')">
								<el-option v-for="item in investmentList" :key="item.id" :label="item.name" :value="item.value"></el-option>
							</el-select>
							<div class="flex text-center custom-box">
								<div class="w-64 lh-40">自定义</div>
								<div class="">
									<el-input class="hide-buttons" v-model="mdsSubscribeWinningBidDTO.keyProjectInvestmentStart" clearable type="number" style="width: 120px;" placeholder="最小金额" @change="changeCustomInvestmentStart('mdsSubscribeWinningBidDTO')" />
									<span class="ml-8">万</span>
								</div>
								<div class="w-10 lh-40 ml-4 mr-4">-</div>
								<div>
									<el-input class="hide-buttons" v-model="mdsSubscribeWinningBidDTO.keyProjectInvestmentEnd" clearable type="number" style="width: 120px;" placeholder="最大金额" @change="changeCustomInvestmentEnd('mdsSubscribeWinningBidDTO')" />
									<span class="ml-8">万</span>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>4、招标人</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeWinningBidDTO.keyTendereeList" @change="selectDepartment('mdsSubscribeWinningBidDTO', 'keyTendereeList', 'keyTenderee')" multiple filterable remote reserve-keyword placeholder="请输入招标人" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>
					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 w-112 lh-40">
							<span>5、中标人</span>
						</div>
						<div class="flex-1">
							<el-select class="w-full" v-model="mdsSubscribeWinningBidDTO.keyBidWinnerList" @change="selectDepartment('mdsSubscribeWinningBidDTO', 'keyBidWinnerList', 'keyBidWinner')" multiple filterable remote reserve-keyword placeholder="请输入招标代理机构" :remote-method="remoteMethod" :loading="loading">
								<el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</div>
					</div>

					<div class="flex justify-start mt-20 pl-64">
						<div class="form-lable mr-10 lh-40">
							<span>6、自定义关键词</span>
						</div>
						<div class="flex-1">
							<el-input class="w-4\/5 lh-40" v-model="mdsSubscribeWinningBidDTO.customKeyword" @change="changeCustomInvestment">
								<el-button slot="suffix" type="primary" size="small" @click="addKeyword('mdsSubscribeWinningBidDTO')">添加</el-button>
							</el-input>
						</div>
					</div>
					<div class="selectedBox pl-176">
						<p class="remind" v-if="mdsSubscribeWinningBidDTO.customKeywordList && mdsSubscribeWinningBidDTO.customKeywordList.length > 2 && mdsSubscribeWinningBidDTO.isKeyWordTip">
							*关键词最多为3个！
						</p>
						<div class="selectedWordList">
							<div class="selectedWord" v-for="(item, i) in mdsSubscribeWinningBidDTO.customKeywordList" :key="i">
								<div class="selectedWordText">
									<span class="wordText">{{ item }}</span>
									<i class="el-icon-close" name="close" size="14" @click="deleteWord(i, 'mdsSubscribeWinningBidDTO')"></i>
								</div>
							</div>
						</div>
						<div class="explan" v-if="mdsSubscribeWinningBidDTO.customKeywordList && mdsSubscribeWinningBidDTO.customKeywordList.length > 0">
							注：
							<span v-for="(item, i) in mdsSubscribeWinningBidDTO.customKeywordList" :key="i">
								<span>
									<span>"</span>
									{{ item }}
									<span>"</span>
								</span>
								<span v-show="mdsSubscribeWinningBidDTO.customKeywordList && mdsSubscribeWinningBidDTO.customKeywordList.length != i + 1">或</span>
							</span>
						</div>
					</div>
				</div>
			</el-form>
		</div>
	</div>
</template>
<script>
import regionSelect from "@/components/regionSelect/index.vue";
export default {
	name: "add",
	components: {
		regionSelect,
	},
	mixins: [],
	props: {
		subscribeId: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			projectType: [
				{
					informationType: "拟建信息",
					informationValue: "60611007",
					checked: false,
				},
				{
					informationType: "土地交易",
					informationValue: "60611010",
					checked: false,
				},
				{
					informationType: "招标信息",
					informationValue: "60611009",
					checked: false,
				},
				{
					informationType: "中标信息",
					informationValue: "60611008",
					checked: false,
				},
			],
			checkboxGroup: [""],
			// 标题点击参数
			activeIndex: 0,
			// 标题选中列表
			subscribeTopicsList: [],
			// 表单
			form: {
				enableAppPush: "10011001",
				subscribeName: "",
				subscribeTopics: "",
				mdsSubscribeProposedProjectDTO: {
					keyAddress: "",
					keyAddressName: "",
					keyProjectChildStage: "",
					keyProjectChildStageName: "",
					keyProjectInvestmentEnd: "",
					keyProjectInvestmentStart: "",
					keyProjectStage: "",
					keyProjectStageName: "",
					keyProjectType: "",
					keyProprietor: "",
					keyProprietorCheck: false,
					keySearch: "",
				},
				mdsSubscribeLandTransactionDTO: {
					keyAddress: "",
					keyAddressName: "",
					keyCommencementDateEnd: "",
					keyCommencementDateStart: "",
					keyLandAreaEnd: "",
					keyLandAreaStart: "",
					keyLandUse: "",
					keyLandUseRight: "",
					keyLandUseRightCheck: false,
					keySearch: "",
				},
				mdsSubscribeTenderDTO: {
					keyAddress: "",
					keyAddressName: "",
					keyAgency: "",
					keyAgencyCheck: false,
					keyProjectPriceEnd: "",
					keyProjectPriceStart: "",
					keyProjectType: "",
					keySearch: "",
					keyTenderee: "",
					keyTendereeCheck: false,
				},
				mdsSubscribeWinningBidDTO: {
					keyAddress: "",
					keyAddressName: "",
					keyBidWinner: "",
					keyBidWinnerCheck: false,
					keyProjectPriceEnd: "",
					keyProjectPriceStart: "",
					keyProjectType: "",
					keySearch: "",
					keyTenderee: "",
					keyTendereeCheck: false,
				},
				pushSettingId: "1",
			},
			// 辅助拟建表单
			mdsSubscribeProposedProjectDTO: {
				keyProjectInvestmentEnd: "",
				keyProjectInvestmentStart: "",
				keyProprietorList: [],
				customKeyword: "",
				customKeywordList: [],
				isKeyWordTip: false,
				keyProjectTypeList: [],
				selectedInvestmentList: "",
				selecedtArealist: [],
			},
			// 辅助土地表单
			mdsSubscribeLandTransactionDTO: {
				keyLandAreaStart: "",
				keyLandAreaEnd: "",
				keyLandUseRightList: [],
				startYear: "",
				endYear: "",
				customKeyword: "",
				customKeywordList: [],
				isKeyWordTip: false,
				selecedtArealist: [],
			},
			// 辅助招标表单
			mdsSubscribeTenderDTO: {
				keyProjectTypeList: [],
				selectedInvestmentList: "",
				keyProjectInvestmentEnd: "",
				keyProjectInvestmentStart: "",
				keyTendereeList: [],
				keyAgencyList: [],
				customKeyword: "",
				customKeywordList: [],
				isKeyWordTip: false,
				selecedtArealist: [],
			},
			// 辅助中标表单
			mdsSubscribeWinningBidDTO: {
				keyProjectTypeList: [],
				selectedInvestmentList: "",
				keyProjectInvestmentEnd: "",
				keyProjectInvestmentStart: "",
				customKeyword: "",
				customKeywordList: [],
				isKeyWordTip: false,
				keyTendereeList: [],
				keyBidWinnerList: [],
				selecedtArealist: [],
			},
			// 地区
			selectArealist: [],
			areaList: [],
			lastSelectedList: [],
			// 金额
			investmentList: [
				{
					value: ",10000",
					name: "小于1亿元",
					id: "1",
				},
				{
					value: "10000,",
					name: "1亿元以上",
					id: "2",
				},
				{
					value: "50000,",
					name: "5亿元以上",
					id: "3",
				},
				{
					value: "100000,",
					name: "10亿元以上",
					id: "4",
				},
				{
					value: "-1,",
					name: "其他",
					id: "5",
				},
			],
			// 拟建信息
			typeList: [],
			keyProjectStageList: [],
			statusList: [],
			isKeyWordTip: false,
			options: [],
			list: [],
			loading: false,
			states: [],
			keyProjectStageNameList: [],
			selectedInvestmentList: "",
			conPO: {
				customInvestmentStart: null,
				customInvestmentEnd: null,
			},
			customKeywordList: [],
			customKeyword: "",
			// 土地交易
			landUseList: [
				{
					id: 1,
					dictLabel: "商服用地",
				},
				{
					id: 2,
					dictLabel: "工矿仓储用地",
				},
				{
					id: 3,
					dictLabel: "公共管理与公共服务用地",
				},
				{
					id: 4,
					dictLabel: "住宅用地",
				},
				{
					id: 5,
					dictLabel: "特殊用地",
				},

				{
					id: 6,
					dictLabel: "交通运输用地",
				},
				{
					id: 7,
					dictLabel: "水利及水域设施用地",
				},
				{
					id: 8,
					dictLabel: "其他用地",
				},
			],
			keyLandUseList: [],
			landAreaList: [
				{
					value: "100000,",
					name: "100000㎡以上",
					id: "1",
				},
				{
					value: "50000,",
					name: "50000㎡以上",
					id: "2",
				},
				{
					value: "10000,",
					name: "10000㎡以上",
					id: "3",
				},
				{
					value: "5000,",
					name: "5000㎡以上",
					id: "4",
				},
				{
					value: ",5000",
					name: "5000㎡以下",
					id: "5",
				},
				{
					value: "-1,",
					name: "其他",
					id: "6",
				},
			],
			selectedLandAreaList: "",
			timeList: [
				{
					id: "30001",
					text: "3个月以内",
					count: 3,
					isAfter: false,
				},
				{
					id: "30002",
					text: "6个月以内",
					count: 6,
					isAfter: false,
				},
				{
					id: "30003",
					text: "1年以内",
					count: 12,
					isAfter: false,
				},
				{
					id: "30004",
					text: "1年以后",
					count: 12 + "+",
					isAfter: true,
				},
				{
					id: "30005",
					text: "2年以后",
					count: 24 + "+",
					isAfter: true,
				},
			],
			minDate: "",
			maxDate: "",
			selectedTime: "",
			// 招标信息
			tenderProjectTypeList: [],
			keyTenderProjectTypeList: [],
		};
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.endYear) {
						disableTime = time.getTime() > new Date(this.endYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						console.log("this.maxDate", this.maxDate);
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.startYear) {
						return time.getTime() < new Date(this.startYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	watch: {
		"form.mdsSubscribeProposedProjectDTO": {
			handler(newVal, oldVal) {
				// 在这里执行相应的操作
				this.listener(newVal, oldVal, "60611007");
			},
			deep: true,
			//立即处理
			immediate: false,
		},
		"form.mdsSubscribeLandTransactionDTO": {
			handler(newVal, oldVal) {
				// 在这里执行相应的操作
				this.listener(newVal, oldVal, "60611010");
			},
			deep: true,
			//立即处理
			immediate: false,
		},
		"form.mdsSubscribeTenderDTO": {
			handler(newVal, oldVal) {
				// 在这里执行相应的操作
				this.listener(newVal, oldVal, "60611009");
			},
			deep: true,
			//立即处理
			immediate: false,
		},
		"form.mdsSubscribeWinningBidDTO": {
			handler(newVal, oldVal) {
				// 在这里执行相应的操作
				this.listener(newVal, oldVal, "60611008");
			},
			deep: true,
			//立即处理
			immediate: false,
		},
	},
	created() {
		if (this.subscribeId) {
			this.getDetail();
		}
		// this.getAreaList();
		this.getProjectStage();
		this.getTimeSelector();
		this.getBidProjectType();
	},
	mounted() {
		this.list = this.states.map(item => {
			return {value: `value:${item}`, label: `label:${item}`};
		});
	},
	methods: {
		toPushSet() {
			this.$emit("toPushSet");
		},
		setFormData(data) {
			this.form.createDate = data.createDate;
			this.form.enableAppPush = data.enableAppPush;
			this.form.expiredTime = data.expiredTime;
			this.form.pushSettingId = data.pushSettingId;
			this.form.subscribeId = data.subscribeId;
			this.form.subscribeName = data.subscribeName;
			this.form.subscribeTopics = data.subscribeTopics;
			this.form.subscriber = data.subscriber;
			this.form.unreadCount = data.unreadCount;
			if (data.mdsSubscribeProposedProjectDTO) {
				this.form.mdsSubscribeProposedProjectDTO = data.mdsSubscribeProposedProjectDTO;
			} else {
				this.form.mdsSubscribeProposedProjectDTO.subscribeId = data.subscribeId;
			}

			if (data.mdsSubscribeLandTransactionDTO) {
				this.form.mdsSubscribeLandTransactionDTO = data.mdsSubscribeLandTransactionDTO;
			} else {
				this.form.mdsSubscribeLandTransactionDTO.subscribeId = data.subscribeId;
			}

			if (data.mdsSubscribeTenderDTO) {
				this.form.mdsSubscribeTenderDTO = data.mdsSubscribeTenderDTO;
			} else {
				this.form.mdsSubscribeTenderDTO.subscribeId = data.subscribeId;
			}

			if (data.mdsSubscribeWinningBidDTO) {
				this.form.mdsSubscribeWinningBidDTO = data.mdsSubscribeWinningBidDTO;
			} else {
				this.form.mdsSubscribeWinningBidDTO.subscribeId = data.subscribeId;
			}
		},
		getDetail() {
			this.$api.subscribe.subscribeDetail({subscribeId: this.subscribeId}).then(res => {
				this.setFormData(res.data);
				this.subscribeTopicsList = res.data.subscribeTopics ? res.data.subscribeTopics.split(",") : [];

				// 详情数据初始化
				// -拟建
				if (res.data.mdsSubscribeProposedProjectDTO) {
					this.mdsSubscribeProposedProjectDTO = Object.assign(this.mdsSubscribeProposedProjectDTO, res.data.mdsSubscribeProposedProjectDTO);
					// 地区
					if (res.data.mdsSubscribeProposedProjectDTO.keyAddressName) {
						this.mdsSubscribeProposedProjectDTO.keyAddressName = JSON.parse(res.data.mdsSubscribeProposedProjectDTO.keyAddressName);
						let province = this.mdsSubscribeProposedProjectDTO.keyAddressName.province;
						let city = this.mdsSubscribeProposedProjectDTO.keyAddressName.city;
						console.log(city);
						let selecedtArealist = [];
						province.map(item => {
							selecedtArealist.push([item.code]);
						});
						city.map(item => {
							selecedtArealist.push([item.code.slice(0, 2) + "0000", item.code]);
						});
						this.mdsSubscribeProposedProjectDTO.selecedtArealist = selecedtArealist;
					}
					// 项目类型
					this.mdsSubscribeProposedProjectDTO.keyProjectTypeList = this.mdsSubscribeProposedProjectDTO.keyProjectType ? this.mdsSubscribeProposedProjectDTO.keyProjectType.split(",") : [];
					// 业主单位
					this.mdsSubscribeProposedProjectDTO.keyProprietorList = this.mdsSubscribeProposedProjectDTO.keyProprietor ? this.mdsSubscribeProposedProjectDTO.keyProprietor.split(",") : [];
					// 项目所处阶段
					this.keyProjectStageList = [];
					if (this.mdsSubscribeProposedProjectDTO.keyProjectStage) {
						let list = this.mdsSubscribeProposedProjectDTO.keyProjectStage.split(",");
						let childCodeList = [];
						this.statusList.map(item => {
							if (item.dictValue == 61001011) {
								childCodeList = item.childCodeList.map(item => item.dictValue);
							}
						});
						console.log(childCodeList);
						list.map(item => {
							if (childCodeList.includes(item)) {
								this.keyProjectStageList.push(["61001011", item]);
							} else {
								this.keyProjectStageList.push([item]);
							}
						});
					}

					// 自定义关键词
					this.mdsSubscribeProposedProjectDTO.customKeywordList = this.mdsSubscribeProposedProjectDTO.keySearch ? this.mdsSubscribeProposedProjectDTO.keySearch.split(",") : [];

					// 投资金额
					if (this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart != -1) {
						this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart = this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart ? this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart / 10000 : "";
						this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd = this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd ? this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd / 10000 : "";
					} else {
						this.mdsSubscribeProposedProjectDTO.selectedInvestmentList = "-1,";
						this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentStart = "";
						this.mdsSubscribeProposedProjectDTO.keyProjectInvestmentEnd = "";
					}
				}

				// -土地
				if (res.data.mdsSubscribeLandTransactionDTO) {
					this.mdsSubscribeLandTransactionDTO = Object.assign(this.mdsSubscribeLandTransactionDTO, res.data.mdsSubscribeLandTransactionDTO);
					// 地区
					if (res.data.mdsSubscribeLandTransactionDTO.keyAddressName) {
						this.mdsSubscribeLandTransactionDTO.keyAddressName = JSON.parse(res.data.mdsSubscribeLandTransactionDTO.keyAddressName);
						let province = this.mdsSubscribeLandTransactionDTO.keyAddressName.province;
						let city = this.mdsSubscribeLandTransactionDTO.keyAddressName.city;
						let selecedtArealist = [];
						province.map(item => {
							selecedtArealist.push([item.code]);
						});
						city.map(item => {
							selecedtArealist.push([item.code.slice(0, 2) + "0000", item.code]);
						});
						this.mdsSubscribeLandTransactionDTO.selecedtArealist = selecedtArealist;
					}
					// 土地用途
					this.keyLandUseList = this.mdsSubscribeLandTransactionDTO.keyLandUse ? this.mdsSubscribeLandTransactionDTO.keyLandUse.split(",") : [];
					// 土地使用权人
					this.mdsSubscribeLandTransactionDTO.keyLandUseRightList = this.mdsSubscribeLandTransactionDTO.keyLandUseRight ? this.mdsSubscribeLandTransactionDTO.keyLandUseRight.split(",") : [];

					// 约定开工日期
					this.mdsSubscribeLandTransactionDTO.startYear = this.mdsSubscribeLandTransactionDTO.keyCommencementDateStart;
					this.mdsSubscribeLandTransactionDTO.endYear = this.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd;
					// 自定义关键词
					this.mdsSubscribeLandTransactionDTO.customKeywordList = this.mdsSubscribeLandTransactionDTO.keySearch ? this.mdsSubscribeLandTransactionDTO.keySearch.split(",") : [];
					console.log("LandTransaction", this.mdsSubscribeLandTransactionDTO);
					// 土地面积
					if (this.mdsSubscribeLandTransactionDTO.keyLandAreaStart == -1) {
						this.selectedLandAreaList = "-1,";
						this.mdsSubscribeLandTransactionDTO.keyLandAreaStart = null;
						this.mdsSubscribeLandTransactionDTO.keyLandAreaEnd = null;
					}
				}

				// -招标
				if (res.data.mdsSubscribeTenderDTO) {
					this.mdsSubscribeTenderDTO = Object.assign(this.mdsSubscribeTenderDTO, res.data.mdsSubscribeTenderDTO);
					// 地区
					if (res.data.mdsSubscribeTenderDTO.keyAddressName) {
						this.mdsSubscribeTenderDTO.keyAddressName = JSON.parse(res.data.mdsSubscribeTenderDTO.keyAddressName);
						let province = this.mdsSubscribeTenderDTO.keyAddressName.province;
						let city = this.mdsSubscribeTenderDTO.keyAddressName.city;
						let selecedtArealist = [];
						province.map(item => {
							selecedtArealist.push([item.code]);
						});
						city.map(item => {
							selecedtArealist.push([item.code.slice(0, 2) + "0000", item.code]);
						});
						this.mdsSubscribeTenderDTO.selecedtArealist = selecedtArealist;
					}
					// 项目类型
					this.mdsSubscribeTenderDTO.keyProjectTypeList = this.mdsSubscribeTenderDTO.keyProjectType ? this.mdsSubscribeTenderDTO.keyProjectType.split(",") : [];

					// 招标人
					this.mdsSubscribeTenderDTO.keyTendereeList = this.mdsSubscribeTenderDTO.keyTenderee ? this.mdsSubscribeTenderDTO.keyTenderee.split(",") : [];

					// 招标代理机构
					this.mdsSubscribeTenderDTO.keyAgencyList = this.mdsSubscribeTenderDTO.keyAgency ? this.mdsSubscribeTenderDTO.keyAgency.split(",") : [];

					// 自定义关键词
					this.mdsSubscribeTenderDTO.customKeywordList = this.mdsSubscribeTenderDTO.keySearch ? this.mdsSubscribeTenderDTO.keySearch.split(",") : [];

					// 招标金额
					if (this.mdsSubscribeTenderDTO.keyProjectPriceStart != -1) {
						this.mdsSubscribeTenderDTO.keyProjectInvestmentEnd = !this.mdsSubscribeTenderDTO.keyProjectPriceEnd ? "不限" : this.mdsSubscribeTenderDTO.keyProjectPriceEnd / 10000;
						this.mdsSubscribeTenderDTO.keyProjectInvestmentStart = !this.mdsSubscribeTenderDTO.keyProjectPriceStart ? "不限" : this.mdsSubscribeTenderDTO.keyProjectPriceStart / 10000;
					} else {
						this.mdsSubscribeTenderDTO.selectedInvestmentList = "-1,";
					}
				}

				// -中标
				if (res.data.mdsSubscribeWinningBidDTO) {
					this.mdsSubscribeWinningBidDTO = Object.assign(this.mdsSubscribeWinningBidDTO, res.data.mdsSubscribeWinningBidDTO);
					// 地区
					if (res.data.mdsSubscribeWinningBidDTO.keyAddressName) {
						this.mdsSubscribeWinningBidDTO.keyAddressName = JSON.parse(res.data.mdsSubscribeWinningBidDTO.keyAddressName);
						let province = this.mdsSubscribeWinningBidDTO.keyAddressName.province;
						let city = this.mdsSubscribeWinningBidDTO.keyAddressName.city;
						let selecedtArealist = [];
						province.map(item => {
							selecedtArealist.push([item.code]);
						});
						city.map(item => {
							selecedtArealist.push([item.code.slice(0, 2) + "0000", item.code]);
						});
						this.mdsSubscribeWinningBidDTO.selecedtArealist = selecedtArealist;
					}
					// 项目类型
					this.mdsSubscribeWinningBidDTO.keyProjectTypeList = this.mdsSubscribeWinningBidDTO.keyProjectType ? this.mdsSubscribeWinningBidDTO.keyProjectType.split(",") : [];

					// 招标人
					this.mdsSubscribeWinningBidDTO.keyTendereeList = this.mdsSubscribeWinningBidDTO.keyTenderee ? this.mdsSubscribeWinningBidDTO.keyTenderee.split(",") : [];
					// 中标人
					this.mdsSubscribeWinningBidDTO.keyBidWinnerList = this.mdsSubscribeWinningBidDTO.keyBidWinner ? this.mdsSubscribeWinningBidDTO.keyBidWinner.split(",") : [];

					// 招标代理机构
					this.mdsSubscribeWinningBidDTO.keyAgencyList = this.mdsSubscribeWinningBidDTO.keyAgency ? this.mdsSubscribeWinningBidDTO.keyAgency.split(",") : [];

					// 自定义关键词
					this.mdsSubscribeWinningBidDTO.customKeywordList = this.mdsSubscribeWinningBidDTO.keySearch ? this.mdsSubscribeWinningBidDTO.keySearch.split(",") : [];

					// 中标金额
					if (this.mdsSubscribeWinningBidDTO.keyProjectPriceStart != -1) {
						this.mdsSubscribeWinningBidDTO.keyProjectInvestmentEnd = !this.mdsSubscribeWinningBidDTO.keyProjectPriceEnd ? "不限" : this.mdsSubscribeWinningBidDTO.keyProjectPriceEnd / 10000;
						this.mdsSubscribeWinningBidDTO.keyProjectInvestmentStart = !this.mdsSubscribeWinningBidDTO.keyProjectPriceStart ? "不限" : this.mdsSubscribeWinningBidDTO.keyProjectPriceStart / 10000;
					} else {
						this.mdsSubscribeWinningBidDTO.selectedInvestmentList = "-1,";
					}
				}
			});
		},
		// 监听对应表单是否填写方法
		listener(newVal, oldVal, subscribeTopicsValue) {
			if (!newVal) {
				return;
			}
			let areAllValuesEmpty = this.areAllValuesEmpty(newVal);
			let existenceValue = !areAllValuesEmpty;
			if (existenceValue) {
				this.subscribeTopicsList = !this.subscribeTopicsList.includes(subscribeTopicsValue) ? [...this.subscribeTopicsList, subscribeTopicsValue] : this.subscribeTopicsList;
			} else {
				this.subscribeTopicsList = this.subscribeTopicsList.filter(item => item !== subscribeTopicsValue);
			}
			this.form.subscribeTopics = this.subscribeTopicsList.join(",");
			this.$forceUpdate();
		},
		// 判断对象是否全为空
		areAllValuesEmpty(obj) {
			if (!obj) return true;
			return Object.values(obj).every(value => !value);
		},
		changeCustomInvestment() {},
		onSubmit() {},
		checkboxClick(index) {
			this.activeIndex = index;
		},
		// 省市区获取
		getAreaList() {
			this.$api.successful
				.getAreaList()
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].type = "省";
						for (let j = 0; j < res.data[i].childrenList.length; j++) {
							res.data[i].childrenList[j].type = "市";
							// for (let k = 0; k < res.data[i].childrenList[j].childrenList.length; k++) {
							// 	res.data[i].childrenList[j].childrenList[k].type = "区";
							// }
						}
					}
					res.data.map(item => {
						// item.childrenList.map(item1 => {
						// 	item1.childrenList.unshift({
						// 		name: "全部区级",
						// 		fullnames: "全部区级",
						// 		code: "全部区级" + item1.code,
						// 	});
						// });
						item.childrenList.unshift({
							name: "全部市级",
							fullnames: "全部市级",
							code: "全部市级" + item.code,
						});
						item.childrenList.map(element => {
							delete element.childrenList;
						});
					});
					let nationwideItem = {
						name: "全国",
						fullnames: "全国",
						nationwide: "全国",
						code: "全国",
					};
					let allProvince = {
						name: "全部省级",
						fullnames: "全部省级",
						code: "全部省级",
					};
					res.data.unshift(nationwideItem, allProvince);
					// if (!(this.memberId == 5 || this.memberId == 6)) {
					// 	// 普通用户地区单选
					this.areaList = res.data;
					// } else {
					// 	// 地区多选
					// 	this.$refs.selectArea.areaList = res.data;
					// }
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		areaChange(obj, fromType) {
			let e = obj.value;
			let nodeArr = obj.nodeArr;
			if (!e) {
				return;
			}
			this.selectArealist = JSON.parse(JSON.stringify(e));
			if (e.length > this.lastSelectedList.length) {
				//添加了按钮
				e.map(item => {
					item.map(i => {
						if (i.indexOf("全部省级") != -1) {
							console.log(123);
							this.addAllProvince();
						}
						if (i.indexOf("全部市级") != -1) {
							this.addAllCity(i.slice(4, 16));
						}
						if (i.indexOf("全部区级") != -1) {
							this.addAllCounty(i.slice(4, 16));
						}
					});
				});
			} else {
				//取消了某个按钮,判断是否是取消了全选
				this.lastSelectedList.map(item => {
					item.map(i => {
						if (i.indexOf("全部省级") != -1) {
							this.deleteAllProvince();
						}
						if (i.indexOf("全部市级") != -1) {
							//判断是否是取消了全选
							// console.log('取消了全选',i)
							let thisALL = false;
							e.map(item1 => {
								if (item1.length == 2) {
									item1.map(i1 => {
										if (i1 == i) {
											thisALL = true;
										}
									});
								}
							});
							if (!thisALL) {
								//取消了全选
								console.log("取消了全选市级", i);
								this.deleteAllCity(i.slice(4, 16));
							}
						}
						if (i.indexOf("全部区级") != -1) {
							let thisALL = false;
							e.map(item1 => {
								if (item1.length == 3) {
									item1.map(i1 => {
										if (i1 == i) {
											thisALL = true;
										}
									});
								}
							});
							if (!thisALL) {
								//取消了全选
								console.log("取消了全选区级", i);
								this.deleteAllCounty(i.slice(4, 16));
							}
						}
					});
				});
			}
			this.lastSelectedList = JSON.parse(JSON.stringify(this.selectArealist));
			// 地区多选
			let selectList = this.selectArealist;
			let nationwide = selectList
				.filter(item => item.length == 1)
				.map(item => item[0])
				.includes("全国")
				? "全国"
				: "";
			let province = selectList.filter(item => item.length == 1).map(item => item[0]);
			let city = selectList.filter(item => item.length == 2).map(item => item[1]);
			let newProvince = []
			province.map(item => {
				let hasCity=false
				city.map(i =>{
					if(item.slice(0,2)==i.slice(0,2)){
						hasCity=true
					}
				})
				if(!hasCity){
					newProvince.push(item)
				}
			})

			let areaObj = {
				province: nationwide || newProvince.join(","),
				city: city.join(","),
			};
			if (!areaObj.province && !areaObj.city) {
				areaObj = null;
			}
			this.$set(this.form[fromType], "keyAddress", areaObj ? JSON.stringify(areaObj) : "");
			let provinceNode = [],
				cityNode = [];
			nodeArr.map(item => {
				if (item.level == 1) {
					provinceNode.push(item);
				} else if (item.level == 2) {
					cityNode.push(item);
				}
			});
			if (provinceNode.length == 0 && cityNode == 0) {
				this.$set(this.form[fromType], "keyAddressName", "");
			} else {
				this.$set(this.form[fromType], "keyAddressName", JSON.stringify({province: provinceNode, city: cityNode, pcVmodel: e}));
			}
		},
		selectType(e, formType) {
			this.$forceUpdate();
			this.form[formType].keyProjectType = e ? e.join(",") : "";
		},
		selectInvestment(e, formType) {
			let arr = this[formType].selectedInvestmentList.split(",");
			let start = "",
				end = "";
			start = arr[0] * 1;
			end = arr[1] * 1;
			if (start != -1) {
				//选的不是‘其他’
				start = start * 10000;
				end = end ? end * 10000 : "";
			}
			if (formType === "mdsSubscribeProposedProjectDTO") {
				this.form[formType].keyProjectInvestmentStart = start;
				this.form[formType].keyProjectInvestmentEnd = end;
			} else {
				this.form[formType].keyProjectPriceStart = start;
				this.form[formType].keyProjectPriceEnd = end;
			}

			this[formType].keyProjectInvestmentStart = null;
			this[formType].keyProjectInvestmentEnd = null;
		},
		changeCustomInvestmentStart(formType) {
			this[formType].selectedInvestmentList = "";
			if (formType === "mdsSubscribeProposedProjectDTO") {
				this.form[formType].keyProjectInvestmentStart =this[formType].keyProjectInvestmentStart? this[formType].keyProjectInvestmentStart * 10000:'';
			} else {
				this.form[formType].keyProjectPriceStart =this[formType].keyProjectInvestmentStart? this[formType].keyProjectInvestmentStart * 10000:'';
			}
		},
		changeCustomInvestmentEnd(formType) {
			this[formType].selectedInvestmentList = "";
			if (this[formType].keyProjectInvestmentEnd == 0) {
				this.$message.error("最大值必须大于0");
				this[formType].keyProjectInvestmentEnd = "";
			}
			if (formType === "mdsSubscribeProposedProjectDTO") {
				// if ((this.form[formType].keyProjectInvestmentStart == -1)) {
				// 	this.form[formType].keyProjectInvestmentStart = "";
				// }
				this.form[formType].keyProjectInvestmentEnd = this[formType].keyProjectInvestmentEnd * 10000;
			} else {
				// if ((this.form[formType].keyProjectPriceStart == -1)) {
				// 	this.form[formType].keyProjectPriceStart = "";
				// }
				this.form[formType].keyProjectPriceEnd =this[formType].keyProjectInvestmentEnd? this[formType].keyProjectInvestmentEnd * 10000: "";
			}
		},
		//  拟建-业主单位联想搜索
		getBidWinner(queryString, callback) {
			this.$api.successful
				.getBidWinner({
					bidWinner: queryString,
				})
				.then(res => {
					let newData = [];
					res.data.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		remoteMethod(query) {
			if (query !== "") {
				this.loading = true;
				this.$api.successful
					.getTenderee({
						tenderee: query,
					})
					.then(res => {
						this.options = res.data;
						this.loading = false;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				this.options = [];
			}
		},
		selectProprietor() {
			this.$forceUpdate();
			if (this.mdsSubscribeProposedProjectDTO.keyProprietorList) this.form.mdsSubscribeProposedProjectDTO.keyProprietor = this.mdsSubscribeProposedProjectDTO.keyProprietorList.join(",");
		},
		//获取项目阶段
		getProjectStage() {
			this.$api.industry
				.getProjectStage()
				.then(res => {
					let statusListMiddleware = res.data;
					let otherItem = {};
					let otherChildItem = {};
					statusListMiddleware.map((item, index) => {
						if (item.dictValue == 61001011) {
							item.childCodeList.map((ite, i) => {
								if (ite.dictValue == 61001007) {
									item.childCodeList.splice(i, 1);
									otherChildItem = ite;
								}
							});
							item.childCodeList.push(otherChildItem);
						}
						if (item.dictValue == 61001015) {
							statusListMiddleware.splice(index, 1);
							otherItem = item;
						}
					});
					statusListMiddleware.push(otherItem);
					this.statusList = statusListMiddleware;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		statusChange(selectedStatusList) {
			console.log("selectedStatusList", selectedStatusList);
			let arr = selectedStatusList.filter(item => item.length == 1).map(item => item[0]);
			let arrChild = selectedStatusList.filter(item => item.length == 2).map(item => item[1]);
			let arrName = [];
			let arrNameChild = [];
			this.statusList.map(item => {
				if (item.dictValue == 61001011 && arrChild.length) {
					item.childCodeList.map(childItem => {
						if (arrChild.indexOf(childItem.dictValue) != -1) {
							arrNameChild.push(childItem.dictLabel);
						}
					});
				} else {
					if (arr.indexOf(item.dictValue) != -1) {
						arrName.push(item.dictLabel);
					}
				}
			});
			this.form.mdsSubscribeProposedProjectDTO.keyProjectStage = [...arr, ...arrChild].join(",");
			this.form.mdsSubscribeProposedProjectDTO.keyProjectStageName = [...arrName, ...arrNameChild].join(",");
		},
		//--自定义关键词--
		addKeyword(fromType) {
			if (this[fromType].customKeyword == "") {
				return;
			}
			if (!this[fromType].customKeywordList) {
				this[fromType].customKeywordList = [];
			}
			if (this[fromType].customKeywordList.length > 2) {
				this[fromType].isKeyWordTip = true;
				return;
			} else {
				this[fromType].isKeyWordTip = false;
			}
			this[fromType].customKeywordList.push(this[fromType].customKeyword);
			this[fromType].customKeyword = "";
			this.form[fromType].keySearch = this[fromType].customKeywordList.join(",");
		},
		//删除自定义关键词
		deleteWord(index, fromType) {
			this[fromType].customKeywordList.splice(index, 1);
			this.form[fromType].keySearch = this[fromType].customKeywordList.join(",");
		},
		// 1.拟建表单 终

		// 2.土地交易始
		selectLandUse() {
			console.log("this.keyLandUseList", this.keyLandUseList);
			this.form.mdsSubscribeLandTransactionDTO.keyLandUse = this.keyLandUseList.join(",");
		},
		selectLandArea() {
			console.log("this.selectedLandAreaList", this.selectedLandAreaList);
			let arr = this.selectedLandAreaList.split(",");
			this.form.mdsSubscribeLandTransactionDTO.keyLandAreaStart = arr[0]? arr[0] * 1:'';
			this.form.mdsSubscribeLandTransactionDTO.keyLandAreaEnd = arr[1]?arr[1] * 1:'';
			this.mdsSubscribeLandTransactionDTO.keyLandAreaStart = null;
			this.mdsSubscribeLandTransactionDTO.keyLandAreaEnd = null;
		},
		changeKeyLandAreaStart() {
			this.selectedLandAreaList = "";
			this.form.mdsSubscribeLandTransactionDTO.keyLandAreaStart = this.mdsSubscribeLandTransactionDTO.keyLandAreaStart;
		},
		changeKeyLandAreaEnd() {
			this.selectedLandAreaList = "";
			this.form.mdsSubscribeLandTransactionDTO.keyLandAreaEnd = this.mdsSubscribeLandTransactionDTO.keyLandAreaEnd;
		},
		selectUseRight(e) {
			this.form.mdsSubscribeLandTransactionDTO.keyLandUseRight = e.length ? e.join(",") : "";
		},
		selectTime() {
			let selectedTimeCount = this.selectedTime;

			// 获取当前月份
			let currentMonth = new Date().getMonth() + 1;

			selectedTimeCount = selectedTimeCount.toString();
			let isAfter = false;
			if (selectedTimeCount.includes("+")) {
				isAfter = true;
				selectedTimeCount = selectedTimeCount.replace("+", "");
			} else {
				isAfter = false;
			}

			// 获取n个月后的月份和年份
			let nMonthsAfter = this.getMonthAfterThreeMonths(currentMonth, selectedTimeCount);
			console.log("nMonthsAfter", nMonthsAfter);
			if (isAfter) {
				this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateStart = nMonthsAfter;
				this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd = "";
			} else {
				this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateStart = this.getMonthAfterThreeMonths(currentMonth, 0);
				this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd = nMonthsAfter;
			}

			this.mdsSubscribeLandTransactionDTO.startYear = "";
			this.mdsSubscribeLandTransactionDTO.endYear = "";
		},
		getMonthAfterThreeMonths(currentMonth, n) {
			// 如果当前月份小于10，前面补0
			currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
			// 获取当前年份
			let currentYear = new Date().getFullYear();
			// 将当前月份加3，并对12进行模运算以确保月份在1-12之间
			let threeMonthsLater = (Number(currentMonth) + Number(n)) % 12;
			// 如果三个月后的月份小于10，前面补0
			threeMonthsLater = threeMonthsLater < 10 ? "0" + threeMonthsLater : threeMonthsLater;
			// 获取三个月后的年份，如果月份加3后超过12，则年份需要增加1
			let threeMonthsYear = currentYear + Math.floor((Number(currentMonth) + Number(n)) / 12);
			// 日期
			const today = new Date();
			return threeMonthsYear + "-" + threeMonthsLater + "-" + today.getDate();
		},
		changeYear() {
			this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateStart = this.mdsSubscribeLandTransactionDTO.startYear;
			this.form.mdsSubscribeLandTransactionDTO.keyCommencementDateEnd = this.mdsSubscribeLandTransactionDTO.endYear;
			this.selectedTime = "";
		},
		getTimeSelector() {
			this.$api.subscribe.getLandTimeSelector().then(res => {
				this.minDate = new Date(res.data.minDateTime + " 00:00:00");
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			});
		},
		// 2.土地交易终

		// 3.招标信息始
		getBidProjectType() {
			this.$api.subscribe.getBidProjectType().then(res => {
				this.tenderProjectTypeList = res.data;
			});
		},
		// 招标人/招标机构/中标人
		selectDepartment(formType, selectedlist, key) {
			this.form[formType][key] = this[formType][selectedlist].join(",");
		},

		// 3.招标信息终

		// 重置
		reset(all) {
			if (this.activeIndex == 0 || all) {
				this.form.mdsSubscribeProposedProjectDTO = {
					keyAddress: "",
					keyAddressName: "",
					keyProjectChildStage: "",
					keyProjectChildStageName: "",
					keyProjectInvestmentEnd: "",
					keyProjectInvestmentStart: "",
					keyProjectStage: "",
					keyProjectStageName: "",
					keyProjectType: "",
					keyProprietor: "",
					keyProprietorCheck: false,
					keySearch: "",
					subscribeId:this.form.mdsSubscribeProposedProjectDTO.subscribeId
				};
				// 辅助拟建表单
				this.mdsSubscribeProposedProjectDTO = {
					keyProjectInvestmentEnd: "",
					keyProjectInvestmentStart: "",
					keyProprietorList: [],
					customKeyword: "",
					customKeywordList: [],
					isKeyWordTip: false,
					keyProjectTypeList: [],
					selectedInvestmentList: "",
					selecedtArealist: [],
				};
				this.keyProjectStageList = [];
			}
			if (this.activeIndex == 1 || all) {
				this.form.mdsSubscribeLandTransactionDTO = {
					keyAddress: "",
					keyAddressName: "",
					keyCommencementDateEnd: "",
					keyCommencementDateStart: "",
					keyLandAreaEnd: "",
					keyLandAreaStart: "",
					keyLandUse: "",
					keyLandUseRight: "",
					keyLandUseRightCheck: false,
					keySearch: "",
					subscribeId:this.form.mdsSubscribeLandTransactionDTO.subscribeId
				};
				// 辅助土地表单
				this.mdsSubscribeLandTransactionDTO = {
					keyLandAreaStart: "",
					keyLandAreaEnd: "",
					keyLandUseRightList: [],
					startYear: "",
					endYear: "",
					customKeyword: "",
					customKeywordList: [],
					isKeyWordTip: false,
					selecedtArealist: [],
				};
				this.keyLandUseList = [];
				this.selectedLandAreaList = "";
				this.selectedTime = "";
			}
			if (this.activeIndex == 2 || all) {
				this.form.mdsSubscribeTenderDTO = {
					keyAddress: "",
					keyAddressName: "",
					keyAgency: "",
					keyAgencyCheck: false,
					keyProjectPriceEnd: "",
					keyProjectPriceStart: "",
					keyProjectType: "",
					keySearch: "",
					keyTenderee: "",
					keyTendereeCheck: false,
					subscribeId:this.form.mdsSubscribeTenderDTO.subscribeId
				};
				// 辅助招标表单
				this.mdsSubscribeTenderDTO = {
					keyProjectTypeList: [],
					selectedInvestmentList: "",
					keyProjectInvestmentEnd: "",
					keyProjectInvestmentStart: "",
					keyTendereeList: [],
					keyAgencyList: [],
					customKeyword: "",
					customKeywordList: [],
					isKeyWordTip: false,
					selecedtArealist: [],
				};
			}
			if (this.activeIndex == 3 || all) {
				this.form.mdsSubscribeWinningBidDTO = {
					keyAddress: "",
					keyAddressName: "",
					keyBidWinner: "",
					keyBidWinnerCheck: false,
					keyProjectPriceEnd: "",
					keyProjectPriceStart: "",
					keyProjectType: "",
					keySearch: "",
					keyTenderee: "",
					keyTendereeCheck: false,
					subscribeId:this.form.mdsSubscribeWinningBidDTO.subscribeId
				};
				// 辅助中标表单
				this.mdsSubscribeWinningBidDTO = {
					keyProjectTypeList: [],
					selectedInvestmentList: "",
					keyProjectInvestmentEnd: "",
					keyProjectInvestmentStart: "",
					customKeyword: "",
					customKeywordList: [],
					isKeyWordTip: false,
					keyTendereeList: [],
					keyBidWinnerList: [],
					selecedtArealist: [],
				};
			}
			if (all) {
				this.form.enableAppPush = "10011001";
				this.form.subscribeName = "";
				this.form.subscribeTopics = "";
				this.form.pushSettingId = "1";
			}
		},
	},
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
